
.notFoundContainer {
  flex-grow: 1;
}

.loaderContainer {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
